<template>
  <b-nav-item-dropdown
    right
    toggle-class="nav-link-notify"
    menu-class="dropdown-menu-media"
  >
    <template #button-content>
      <feather-icon
        :title="$t('tooltips.notifications')"
        :badge="notificationsList2.notificationsCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("notifications.title") }}
        </h4>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list"
      tagname="ul"
    >
      <template v-if="notificationsList2.notificationsCount">
        <b-link
          v-for="notification in notificationsList2.notifications"
          :key="notification.id"
          class="d-flex"
          :href="notification.targetURL"
        >
          <div class="media d-flex align-items-start">
            <div class="media-left">
              <b-avatar :src="notification.imageURL" size="32" />
            </div>
            <div class="media-body">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>
              </p>
              <small class="notification-text">{{
                notification.message
              }}</small>
            </div>
          </div>
        </b-link>
      </template>

      <template v-else>
        <li class="text-center my-2">
          <div class="medium-text">
            {{ $t("notifications.empty-notifications") }}
          </div>
        </li>
      </template>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-link class="btn btn-primary btn-block" :to="notificationsLocation">
        See All
      </b-link>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BLink, BAvatar } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import { NOTIFICATIONS_ACTIONS } from "@/store/notifications/notifications-store-constants";

export default {
  components: {
    BLink,
    BNavItemDropdown,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
  },
  data() {
    return {
      notifications: [],
      notificationsCount: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },

  mixins: [ToastNotificationsMixin],

  computed: {
    unreadNotificationsCount() {
      return this.$store.state.notifications.notifications.length || 0;
    },
    currentMember() {
      return this.$store.getters.loggedUser.key;
    },
    notificationsList2() {
      const currentMember = this.currentMember;
      const notificationArray = this.$store.state.notifications.notifications;
      const uniqueArray = notificationArray.filter((value, index, self) => {
        if (value.type && value.type == "chat") {
          return (
            index ===
            self.findIndex(
              (t) =>
                t.message === value.message && t.memberKey === currentMember
            )
          );
        }
        return value
      });
      this.notifications = uniqueArray || [];
      this.notificationsCount = uniqueArray.length || 0;
      return {
        notifications: uniqueArray || [],
        notificationsCount: uniqueArray.length || 0,
      };
    },
    isNotificationsView() {
      return this.$route.name === "myself-notifications";
    },
    notificationsLocation() {
      if (!this.isNotificationsView) {
        return {
          name: "myself-notifications",
          params: {
            communityId: this.$route.params.communityId,
          },
        };
      }
      return null;
    },
  },
  watch: {
    isPushEnabled() {
      this.listenToNotifications();
    },
  },
  methods: {
    requestForceReload() {
      if (!this.isNotificationsView) {
        return;
      }
      this.$store.dispatch(NOTIFICATIONS_ACTIONS.requestForceReload);
    },
  },
};
</script>

<style lang="scss" scoped>
.medium-text {
  font-size: 1rem;
  font-weight: 400;
  color: #6e6b7b;
}

.dropdown-menu-media {
  width: 24rem;

  .dropdown-menu-header {
    border-bottom: 1px solid #ebe9f1;
  }

  .scrollable-container {
    overflow-y: auto;
  }

  .media-list {
    padding-left: 0;
  }

  .media-heading {
    margin-bottom: 5px;
  }

  .media {
    padding: 1rem;
    border-bottom: 1px solid #ebe9f1;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.notification-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
